<template>
  <div id="content">
    <div id="loading" v-if="loading">
      <div class="dot-flashing"></div>
    </div>
    <div class="wrapper">
      <header class="pb-3">
        <h1>{{ $t('message.search') }}</h1>
      </header>
      <div>
        <table class="mdl-data-table mdl-js-data-table mdl-data-table--selectable mdl-table-custom sm-2" >
          <thead>
          <tr>
            <th>{{ $t('message.name') }}</th>
            <th class="sm-hidden">{{ $t('message.deposit_date') }}</th>
            <th>{{ $t('message.actions') }}</th>
          </tr>
          </thead>
          <tbody>

          <tr
            v-for="(folder, folderIndex) in folders"
            :key="'f' + folderIndex"
          >
            <td
              :data-label="$t('message.name') + ' :'"
            >
              <div class="folder-container">
                <i class="el-type fas fa-folder"></i><span class="ml-i2 font-weight-bold">{{ folder.name }}</span>
              </div>
            </td>
            <td :data-label="$t('message.deposit_date') + ' :'" class="sm-hidden"></td>
            <td
              class="actions"
              :data-label="$t('message.actions') + ' :'"
            >
              <button :title="$t('message.download')" @click="downloadFolder(folder)"><i class="fas fa-download"></i></button>
              <router-link :title="$t('message.view_file_location')" class="button text-primary" :to="{path: getUrl(folder)}" draggable="false">
                <i class="mdi mdi-folder-eye mdi-18px"></i>
              </router-link>
            </td>
          </tr>
          <tr
            v-for="(document, documentIndex) in documents"
            :key="'d' +documentIndex"
          >
            <td :data-label="$t('message.name') + ' :'">
              <i :class='"el-type mdi " + resolveIcon(document)'></i><span class="ml-i1 font-weight-bold">{{ document.name }}.{{ document.extension }}</span>
            </td>
            <td :data-label="$t('message.deposit_date') + ' :'" class="sm-hidden">
              {{ document.createdAt ? formatDate(document.createdAt) : '-' }}
            </td>
            <td class="actions" :data-label="$t('message.actions') + ' :'">
              <button
                :title="$t('message.download')"
                @click="downloadDocument(document)"
              >
                <i class="fas fa-download"></i>
              </button>
              <button
                v-if="isPreviewable(document)"
                :title="$t('message.view')"
                @click="openPreviewDocumentDialog(document)"
              >
                <i class="fas fa-eye"></i>
              </button>
              <button
                :title="$t('message.information')"
                @click="$refs.documentDetails.showDocumentDetails(document)"
              >
                <i class="fas fa-info-circle"></i>
              </button>
              <router-link :title="$t('message.view_file_location')" class="button text-primary" :to="{path: getUrl(document)}" draggable="false">
                <i class="mdi mdi-folder-eye mdi-18px"></i>
              </router-link>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <documents-details ref="documentDetails"></documents-details>
    </div>

    <div>
      <PreviewPdfDialog
        v-if="documentToPreview && destroyPreviewPdfComponent && isPdf(documentToPreview)"
        :preview-pdf-dialog-state="previewPdfDialogState"
        :document="documentToPreview"
        @closePreviewPdfDialog="closePreviewPdfDialog($event)"
      ></PreviewPdfDialog>

      <PreviewImgDialog
        v-if="documentToPreview && destroyPreviewImgComponent && isImg(documentToPreview)"
        :preview-img-dialog-state="previewImgDialogState"
        :document="documentToPreview"
        @closePreviewImgDialog="closePreviewImgDialog($event)"
      ></PreviewImgDialog>

      <PreviewXlsDialog
        v-if="documentToPreview && destroyPreviewXlsComponent && isXls(documentToPreview)"
        :preview-xls-dialog-state="previewXlsDialogState"
        :document="documentToPreview"
        @closePreviewXlsDialog="closePreviewXlsDialog($event)"
      ></PreviewXlsDialog>

      <PreviewCsvDialog
        v-if="documentToPreview && destroyPreviewCsvComponent && isCsv(documentToPreview)"
        :preview-csv-dialog-state="previewCsvDialogState"
        :document="documentToPreview"
        @closePreviewCsvDialog="closePreviewCsvDialog($event)"
      ></PreviewCsvDialog>

      <PreviewTxtDialog
        v-if="documentToPreview && destroyPreviewTxtComponent && isTxt(documentToPreview)"
        :preview-txt-dialog-state="previewTxtDialogState"
        :document="documentToPreview"
        @closePreviewTxtDialog="closePreviewTxtDialog($event)"
      ></PreviewTxtDialog>
    </div>

  </div>
</template>

<script>
import config from '@/config'
import Vue from 'vue'
import DefaultLayout from '../../layouts/DefaultLayout.vue'
import { documentsMixin } from '@/mixin/documentsMixin'
import PreviewPdfDialog from '@/components/Dialog/Documents/DocumentDialog/PreviewPdfDialog'
import PreviewImgDialog from '@/components/Dialog/Documents/DocumentDialog/PreviewImgDialog'
import PreviewXlsDialog from '@/components/Dialog/Documents/DocumentDialog/PreviewXlsDialog'
import PreviewCsvDialog from '@/components/Dialog/Documents/DocumentDialog/PreviewCsvDialog'
import PreviewTxtDialog from '@/components/Dialog/Documents/DocumentDialog/PreviewTxtDialog'
import DocumentsDetails from '@/components/DocumentsDetails'
import slugify from 'slugify'

export default {
  name: 'Search',
  mixins: [documentsMixin],
  components: {
    DocumentsDetails,
    PreviewPdfDialog,
    PreviewImgDialog,
    PreviewXlsDialog,
    PreviewCsvDialog,
    PreviewTxtDialog
  },
  data: () => ({
    loading: false,
    documents: [],
    folders: []
  }),
  created () {
    this.$emit('update:layout', DefaultLayout)
    document.title = config.title + ' - ' + this.$t('message.search')
    this.search()
  },
  methods: {
    search: function () {
      this.loading = true
      Vue.prototype.$http
        .get(config.apiUrl + '/search', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            search: this.$route.params.searchString,
            vault: this.$store.state.currentVault.id
          }
        })
        .then((response) => {
          if (response.status === 200) {
            const documents = JSON.parse(response.data.documents)
            this.folders = JSON.parse(response.data.folders)
            if (documents) {
              this.documents = documents
              this.nextPage = this.nextPage + 1
            }
          }
          this.loading = false
          this.$store.commit('setHandlingDocumentScroll', false)
        })
        .catch(e => {
          const response = e.response
          if (response.status === 404) {
            this.$store.dispatch('messages/addMessage',
              { text: this.$t('message.service_maintenance'), type: 'danger' }
            )
          }
          if (response.status === 500) {
            this.$store.dispatch('messages/addMessage',
              { text: this.$t('message.error_fetching_items'), type: 'danger' }
            )
          }
          this.loading = false
        })
        .finally(() => { this.loading = false })
    },
    getUrl: function (item) {
      if (this.$store.getters.getCurrentRootFolders.length > 0) {
        for (const i in this.$store.getters.getCurrentRootFolders) {
          const folder = this.$store.getters.getCurrentRootFolders[i]
          if (item.folderId) {
            if (item.folderId === folder.id) {
              return '/mes-documents/' + slugify(folder.section.name, { lower: true }) + '?folder=' + item.folderId
            }
          } else if (item.section.id === folder.section.id) {
            let url = '/mes-documents/' + slugify(folder.section.name, { lower: true })
            if ((item.parent && item.parent.id)) {
              url += '?folder=' + item.parent.id
            }
            return url
          }
        }
      }
    },
  },
  watch: {
    '$route.params.searchString': function () {
      this.search()
    }
  }
}
</script>
