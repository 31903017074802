import Vue from 'vue'
import config from '@/config'
import FileSaver from 'file-saver'

export const documentsMixin = {
  data: () => ({
    loading: false,
    documentToPreview: null,
    previewPdfDialogState: false,
    previewImgDialogState: false,
    previewXlsDialogState: false,
    previewCsvDialogState: false,
    previewTxtDialogState: false,
    destroyPreviewPdfComponent: true,
    destroyPreviewImgComponent: true,
    destroyPreviewXlsComponent: true,
    destroyPreviewCsvComponent: true,
    destroyPreviewTxtComponent: true,
    files: [],
    nextPage: 1
  }),
  methods: {
    resolveIcon (document) {
      if (this.isPdf(document)) {
        return 'mdi-file-pdf-outline'
      }

      if (this.isImg(document)) {
        return 'mdi-file-image-outline'
      }

      if (this.isXls(document)) {
        return 'mdi-file-excel-outline'
      }

      if (this.isCsv(document)) {
        return 'mdi-file-table-outline'
      }

      if (this.isTxt(document)) {
        return 'mdi-file-document-outline'
      }

      const word = ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']

      if (word.includes(document.mimeType)) {
        return 'mdi-file-word-outline'
      }

      const powerpoint = ['application/vnd.oasis.opendocument.presentation', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation']

      if (powerpoint.includes(document.mimeType)) {
        return 'mdi-file-powerpoint-outline'
      }

      const audio = ['audio/aac', 'audio/midi', 'audio/x-midi', 'audio/mp3', 'audio/oga', 'audio/wav', 'audio/weba']

      if (audio.includes(document.mimeType)) {
        return 'mdi-file-music-outline'
      }

      const video = ['video/x-msvideo', 'video/mpeg', 'video/ogv', 'video/mp2t', 'application/vnd.visio', 'video/webm']

      if (video.includes(document.mimeType)) {
        return 'mdi-file-video-outline'
      }

      const archive = ['application/gzip', 'application/x-freearc', 'application/zip', 'application/rar', 'application/x-bzip', 'application/x-bzip2', 'application/java-archive', 'application/x-tar', 'application/x-7z-compressed']

      if (archive.includes(document.mimeType)) {
        return 'mdi-archive-outline'
      }

      return 'mdi-file-document-outline'
    },
    downloadDocument (document) {
      if (!document) {
        return
      }

      this.loading = true

      Vue.prototype.$http
        .get(config.apiUrl + '/documents/' + document.id, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            vault: this.$store.getters.getCurrentVault.id
          },
          responseType: 'blob'
        })
        .then(response => {
          if (response.status === 200) {
            const headers = response.headers
            let filename = document.name + '.' + document.extension
            if (headers['content-name']) {
              filename = decodeURIComponent(escape(headers['content-name']))
            }
            const blob = new Blob([response.data], { type: headers['content-type'] })
            FileSaver.saveAs(blob, filename)

            if (document.read !== true) {
              this.putDocument(document)
            }

            this.loading = false
          }
        })
        .catch(e => {
          this.loading = false
        })
    },
    downloadFolder (folder) {
      if (!folder || !this.$store.getters.getCurrentRootFolders) {
        return
      }

      const queryFolder = this.$route.query.folder
      const parentFolder = queryFolder || this.$store.getters.getCurrentRootFolders.id || folder.id

      this.loading = true

      Vue.prototype.$http
        .get(config.apiUrl + '/documents', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            vault: this.$store.getters.getCurrentVault.id,
            format: 'zip',
            folder: parentFolder
            // tree: folder.id
          },
          responseType: 'blob'
        })
        .then(response => {
          if (response.status === 200) {
            const headers = response.headers
            const blob = new Blob([response.data], { type: headers['content-type'] })
            FileSaver.saveAs(blob, folder.name)
          }

          this.loading = false
        })
        .catch(e => {
          this.loading = false
        })
    },
    isPreviewable (document) {
      if (this.isPdf(document)) {
        return true
      }

      if (this.isImg(document)) {
        return true
      }

      if (this.isCsv(document)) {
        return true
      }

      if (this.isTxt(document)) {
        return true
      }
    },
    isPdf (document) {
      const pdf = ['application/pdf']

      return pdf.includes(document.mimeType)
    },
    isImg (document) {
      const image = ['image/png', 'image/jpg', 'image/jpeg', 'image/svg+xml', 'image/gif', 'image/bmp', 'image/vnd.microsoft.icon', 'image/tiff', 'image/webp']

      return image.includes(document.mimeType)
    },
    isXls (document) {
      const excel = ['application/vnd.oasis.opendocument.spreadsheet', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']

      return excel.includes(document.mimeType)
    },
    isCsv (document) {
      const table = ['text/csv']

      return table.includes(document.mimeType)
    },
    isTxt (document) {
      const txt = ['text/plain']

      return txt.includes(document.mimeType)
    },
    formatDate (currentDate) {
      const timestamp = Date.parse(currentDate)
      const date = new Date(timestamp)
      let day = date.getDate()
      let month = date.getMonth() + 1
      let hour = date.getHours()
      let min = date.getMinutes()

      day = (day < 10 ? '0' : '') + day
      month = (month < 10 ? '0' : '') + month
      hour = (hour < 10 ? '0' : '') + hour
      min = (min < 10 ? '0' : '') + min

      return day + '/' + month + '/' + date.getFullYear() + ' ' + hour + ':' + min
    },
    openPreviewDocumentDialog (document) {
      if (this.isPdf(document)) {
        this.previewPdfDialogState = true
        this.destroyPreviewPdfComponent = true
      } else if (this.isImg(document)) {
        this.previewImgDialogState = true
        this.destroyPreviewImgComponent = true
      } else if (this.isCsv(document)) {
        this.previewCsvDialogState = true
        this.destroyPreviewCsvComponent = true
      } else if (this.isTxt(document)) {
        this.previewTxtDialogState = true
        this.destroyPreviewTxtComponent = true
      }

      this.documentToPreview = document
    },
    closePreviewPdfDialog () {
      this.previewPdfDialogState = false
      this.destroyPreviewPdfComponent = false
    },
    closePreviewImgDialog () {
      this.previewImgDialogState = false
      this.destroyPreviewImgComponent = false
    },
    closePreviewXlsDialog () {
      this.previewXlsDialogState = false
      this.destroyPreviewXlsComponent = false
    },
    closePreviewCsvDialog () {
      this.previewCsvDialogState = false
      this.destroyPreviewCsvComponent = false
    },
    closePreviewTxtDialog () {
      this.previewTxtDialogState = false
      this.destroyPreviewTxtComponent = false
    }
  }
}
