<template>
  <div id="document-details" :class="detailsClass">
    <div id="volet-header">
      <div class="float-left header-document-name">
        {{ currentDocument ? truncate(currentDocument) : '-' }}
      </div>
      <div class="float-right">
        <button class="close-doc-details" data-toggle="tooltip" data-placement="top" :title="$t('message.close')" @click="hideDocumentDetails()"><i class="fas fa-times"></i></button>
      </div>
      <div class="clear-both"></div>
      <div
        :class=" (documentDetailsStatus) ? 'active' : ''"
        class="header-details"
        @click="showDocumentDetails(currentDocument)"
      >
        {{ $t('message.details') }}
      </div>
      <div
        :class=" (documentHistoryStatus) ? 'active' : ''"
        class="header-history"
        @click="showDocumentHistory(currentDocument)"
      >
        {{ $t('message.activity') }}</div>
      <div class="clear-both"></div>
    </div>
    <div v-if="documentDetailsStatus" id="volet-content">
      <h3>
        {{ $t('message.system_properties') }}
      </h3>
      <div v-if="documentDetailsLoader" class="dot-flashing"></div>
      <table v-if="!documentDetailsLoader">
        <tbody>
        <tr>
          <td class="type-label">{{ $t('message.type') }}</td>
          <td class="type-value">{{ currentDocument ? currentDocument.extension : '-' }}</td>
        </tr>
        <tr>
          <td class="size-label">{{ $t('message.size') }}</td>
          <td class="size-value">{{ currentDocument ? resolveSize(currentDocument.size) : '-' }}</td>
        </tr>
        <tr>
          <td class="created-at-label">{{ $t('message.creation_date') }}</td>
          <td class="created-at-value">{{ currentDocument ? formatDate(currentDocument.createdAt) : '-' }}</td>
        </tr>
        <tr>
          <td class="check-label">{{ $t('message.file_integrity') }}</td>
          <td class="check-value">
            <v-chip
              color="teal"
              text-color="white"
              v-if="isCurrentDocumentIntact"
            >
              <v-avatar left>
                <v-icon>mdi-checkbox-marked-circle</v-icon>
              </v-avatar>
              {{ $t('message.verified') }}
            </v-chip>
            <v-chip
              color="red"
              text-color="white"
              v-if="!isCurrentDocumentIntact"
            >
              <v-avatar left>
                <v-icon>mdi-close-circle</v-icon>
              </v-avatar>
              {{ $t('message.corrupted_file') }}
            </v-chip>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-if="documentHistoryStatus" id="volet-history">
      <div v-if="documentHistoryLoader" class="dot-flashing"></div>
      <div v-if="!documentHistoryLoader">
        <div v-for="(log, logIndex) in currentLogs"
             :key="'l' + logIndex"
             class="log-container mb-5"
        >
          <h5 class="log-date">{{ formatDate(log.createdAt) }}</h5>
          <p class="log-content">{{ log.action }} <br> par {{ log.user.firstName + ' ' + log.user.lastName }} <br> ({{ log.ip + ' - ' + log.browser }})</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import config from '@/config'

export default {
  name: 'DocumentsDetails',
  data: () => ({
    documentDetailsLoader: false,
    documentDetailsStatus: false,
    documentHistoryLoader: false,
    documentHistoryStatus: false,
    currentDocument: null,
    currentLogs: [],
    isCurrentDocumentIntact: false
  }),
  computed: {
    user () {
      return this.$store.getters.getUser
    },
    detailsClass () {
      return 'sidenav' + (this.user.rcu ? '-clients' : '') + ((this.documentDetailsStatus || this.documentHistoryStatus) ? ' openDocumentDetails' : '')
    }
  },
  methods: {
    showDocumentDetails (doc) {
      this.currentDocument = doc
      this.documentDetailsLoader = true
      this.documentDetailsStatus = true
      this.documentHistoryStatus = false
      this.documentHistoryLoader = false

      Vue.prototype.$http
        .get(config.apiUrl + '/documents/' + this.currentDocument.id, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            vault: this.$store.getters.getCurrentVault.id,
            details: true
          }
        })
        .then(response => {
          if (response.status === 200) {
            this.currentDocument = response.data

            this.checkDocumentIntegrity(this.currentDocument)
          }
        })
        .catch(e => {
          this.documentDetailsLoader = false
        })
    },
    showDocumentHistory (doc) {
      this.currentDocument = doc
      this.documentDetailsStatus = false
      this.documentDetailsLoader = false
      this.documentHistoryStatus = true
      this.documentHistoryLoader = true

      Vue.prototype.$http
        .get(config.apiUrl + '/logs', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            vault: this.$store.getters.getCurrentVault.id,
            user: this.$store.getters.getUser.id,
            documentId: this.currentDocument.id
          }
        })
        .then(response => {
          if (response.status === 200) {
            this.currentLogs = response.data
          }

          this.documentHistoryLoader = false
        })
        .catch(e => {
          this.documentHistoryLoader = false
        })
    },
    hideDocumentDetails () {
      this.documentDetailsLoader = false
      this.documentDetailsStatus = false
      this.documentHistoryStatus = false
      this.documentHistoryLoader = false
    },
    formatDate (currentDate) {
      const timestamp = Date.parse(currentDate)
      const date = new Date(timestamp)
      let day = date.getDate()
      let month = date.getMonth() + 1
      let hour = date.getHours()
      let min = date.getMinutes()

      day = (day < 10 ? '0' : '') + day
      month = (month < 10 ? '0' : '') + month
      hour = (hour < 10 ? '0' : '') + hour
      min = (min < 10 ? '0' : '') + min

      return day + '/' + month + '/' + date.getFullYear() + ' ' + hour + ':' + min
    },
    truncate (document) {
      const name = document.name + '.' + document.extension

      if (name.length > 20) {
        return name.substring(0, 20) + '...'
      }

      return name
    },
    resolveSize (size) {
      const sizes = [
        { name: 'Bytes', round: 0 },
        { name: 'Ko', round: 0 },
        { name: 'Mo', round: 2 },
        { name: 'Go', round: 2 },
        { name: 'To', round: 2 }
      ]

      if (size === 0) {
        return '0 Byte'
      }

      const i = parseInt(Math.floor(Math.log(size) / Math.log(1024)))

      return (size / Math.pow(1024, i)).toFixed(sizes[i].round) + ' ' + sizes[i].name
    },
    checkDocumentIntegrity (doc) {
      Vue.prototype.$http
        .get(config.apiUrl + '/documents/' + this.currentDocument.id, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: {
            vault: this.$store.getters.getCurrentVault.id,
            check: true
          }
        })
        .then(response => {
          this.isCurrentDocumentIntact = response.status === 200
          this.documentDetailsLoader = false
        })
        .catch(e => {
          this.isCurrentDocumentIntact = false
          this.documentDetailsLoader = false
        })
    },
  }
}
</script>
